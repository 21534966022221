import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import Loader from "./Loader";
import Pagination from "./Pagination";

const Table = ({ header, sort, total, onSort, page, onPageChange, loading, children, pageSize = 10 }) => {
  return (
    <div className="border rounded-md mt-5 flex-1 overflow-x-auto no-scrollbar">
      <table className="min-w-max w-full bg-white">
        <thead className="text-left border-b">
          <tr>
            {header.map((item, index) => (
              <th
                key={index}
                colSpan={item.colSpan}
                className={`py-5 px-3 font-semibold border-b ${item.key && "hover:bg-neutral-50 cursor-pointer"} ${item.property}`}
                onClick={() => {
                  item.key && onSort(item.key);
                }}>
                <div className="flex justify-between items-center gap-3">
                  <div>{item.title}</div>
                  {item.key && (
                    <div className="flex flex-col justify-center">
                      <IoIosArrowUp className={sort[item.key] === 1 ? "text-primary" : "text-secondary"} />
                      <IoIosArrowDown className={sort[item.key] === -1 ? "text-primary" : "text-secondary"} />
                    </div>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="relative">
          {loading ? (
            <tr>
              <td colSpan={header.reduce((acc, item) => acc + (item.colSpan || 1), 0)}>
                <div className="py-4 flex w-full justify-center bg-gray-50">
                  <Loader />
                </div>
              </td>
            </tr>
          ) : total === 0 ? (
            <tr>
              <td colSpan={header.reduce((acc, item) => acc + (item.colSpan || 1), 0)} className="py-4 text-center bg-neutral-50">
                Aucune donnée trouvée
              </td>
            </tr>
          ) : (
            children
          )}
        </tbody>
        {onPageChange && (
          <tfoot>
            <tr className="border-t">
              <td colSpan={header.reduce((acc, item) => acc + (item.colSpan || 1), 0)} className="py-2 text-center bg-white">
                <Pagination page={page} setPage={onPageChange} last={Math.ceil(total / pageSize)} />
              </td>
            </tr>
          </tfoot>
        )}
      </table>
    </div>
  );
};

export default Table;
