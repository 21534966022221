import { useEffect, useState } from "react";
import api from "../../services/api";
import Loader from "../../components/Loader";
import Switch from "../../components/Switch";

const Parcours = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [isShareIdEnabled, setIsShareIdEnabled] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const resS = await api.get("/stats/success");
      if (!resS.ok) throw resS;

      setData(resS.data);

      const resSh = await api.get("/share-id/is-enabled");
      if (!resSh.ok) throw resSh;

      setIsShareIdEnabled(resSh.data);
    } catch (err) {
      console.error(err.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSwitch = async (value) => {
    setIsShareIdEnabled(value);
    try {
      const res = await api.put("/share-id/enable", { value });
      if (!res.ok) throw res;
    } catch (err) {
      setIsShareIdEnabled(!value);
      console.error(err.message);
    }
  };

  if (loading) return <Loader />;

  return (
    <div className="p-8 space-y-8">
      <div className="rounded-md bg-white border flex items-center justify-between p-4">
        <h1 className="text-xl font-semibold">Activer ShareId</h1>
        <Switch checked={isShareIdEnabled} onChange={handleSwitch} />
      </div>
      <div className="rounded-md bg-white border grid grid-cols-2">
        <div className="col-span-2 flex justify-between items-center border-b p-4">
          <h1 className="text-xl font-semibold">Parcours</h1>
        </div>
        <div className="col-span-2 flex flex-col items-center p-8">
          <h1 className="w-full text-center border-b text-2xl font-semibold pb-8">Taux de réussite</h1>
          <div className={`w-full grid ${isShareIdEnabled ? "grid-cols-3" : "grid-cols-2"} py-8`}>
            <div className="flex flex-col items-center justify-between border-r">
              <label className="text-md font-semibold">Parcours A</label>
              <CircleScore score={data?.aRoute?.successRate.toFixed(0)} />
              <label className="text-md font-light">
                Nombre de parcours A effectués : <span className="font-semibold">{data?.aRoute?.total}</span>
              </label>
            </div>
            <div className="flex flex-col items-center justify-between">
              <label className="text-md font-semibold">Parcours B</label>
              <CircleScore score={data?.bRoute?.successRate.toFixed(0)} />
              <label className="text-md font-light">
                Nombre de parcours B effectués : <span className="font-semibold">{data?.bRoute?.total}</span>
              </label>
            </div>
            {isShareIdEnabled && (
              <div className="flex flex-col items-center justify-between border-l">
                <label className="text-md font-semibold">ShareID</label>
                <CircleScore score={data?.shareId?.successRate.toFixed(0)} />
                <label className="text-md font-light">
                  Nombre de parcours ShareId effectués : <span className="font-semibold">{data?.shareId?.total}</span>
                </label>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="rounded-md bg-white border flex justify-between gap-6 p-6">
        <div className="flex flex-col gap-4 w-full">
          <div className="border rounded-md flex justify-start items-center p-6 h-40">
            <div className="flex flex-col justify-between gap-1">
              <label className="text-md">Nombre d'échecs de reconnaissance du vivant</label>
              <label className="text-3xl font-semibold mt-3">{data?.aRoute?.failedLivenessCount}</label>
            </div>
          </div>
          <div className="border rounded-md flex justify-start items-center p-6 h-40">
            <div className="flex flex-col justify-between  gap-1">
              <label className="text-md">Nombre d'échecs de reconnaissance biométrique</label>
              <label className="text-3xl font-semibold mt-3">{data?.aRoute?.failedBiometryCount}</label>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4 w-full">
          <div className="border rounded-md flex justify-start items-center p-6 h-40">
            <div className="flex flex-col justify-between gap-3">
              <label className="text-md">Nombre d'échecs de reconnaissance du vivant</label>
              <label className="text-3xl font-semibold mt-3">{data?.bRoute?.failedLivenessCount}</label>
            </div>
          </div>
          <div className="border rounded-md flex justify-start items-center p-6 h-40">
            <div className="flex flex-col justify-between gap-3">
              <label className="text-md">Nombre d'échecs de reconnaissance biométrique</label>
              <label className="text-3xl font-semibold mt-3">{data?.bRoute?.failedBiometryCount}</label>
            </div>
          </div>
          {isShareIdEnabled && (
            <div className="border rounded-md flex justify-start items-center p-6 h-40">
              <div className="flex flex-col justify-between gap-3">
                <label className="text-md">Nombre d'échecs ShareID</label>
                <label className="text-3xl font-semibold mt-3">{data?.bRoute?.failedShareIdCount}</label>
              </div>
            </div>
          )}
        </div>
        {isShareIdEnabled && (
          <div className="flex flex-col gap-4 w-full">
            <div className="border rounded-md flex justify-start items-center p-6 h-40">
              <div className="flex flex-col justify-between gap-3">
                <label className="text-md">Nombre d'échecs de reconnaissance biométrique</label>
                <label className="text-3xl font-semibold mt-3">{data?.shareId?.failedCount}</label>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const CircleScore = ({ score, maxScore = 100 }) => {
  const radius = 30;
  const circumference = 2 * Math.PI * radius;
  const percentage = score / maxScore;
  const strokeDashoffset = circumference * (1 - percentage);

  return (
    <div className="relative flex items-center justify-center w-56 h-56">
      <svg className="rotate-[-90deg]" width="100%" height="100%" viewBox="0 0 80 80">
        <circle cx="40" cy="40" r={radius} fill="transparent" stroke="#D9D9D9" strokeWidth="6" />
        <circle
          cx="40"
          cy="40"
          r={radius}
          fill="transparent"
          stroke="#0DBB29"
          strokeWidth="6"
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset || 0}
          strokeLinecap="round"
        />
      </svg>
      <div className="absolute">
        <span className="text-3xl font-semibold">{`${Math.round(score)}%`}</span>
      </div>
    </div>
  );
};

export default Parcours;
