import React from "react";

const Loader = ({ className = "w-16 h-16" }) => {
  return (
    <div className="flex items-center justify-center m-4">
      <div className={`border-l-primary animate-spin inline-block ${className} border-[6px] rounded-full`} role="status" />
    </div>
  );
};

export default Loader;
