import { useEffect, useState } from "react";
import api from "../../services/api";
import { MdAddCircleOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Table from "../../components/Table";
import LoadingButton from "../../components/LoadingButton";
import Modal from "../../components/Modal";
import toast from "react-hot-toast";

const HEADER = [
  { title: "Email", key: "email" },
  { title: "Connexions", property: "w-32" },
  { title: "Onboarding OK", property: "w-36" },
  { title: "Échecs du vivant", property: "w-40" },
  { title: "Échecs biométrie", property: "w-40" },
  { title: "Échecs ShareID", property: "w-36" },
];

const List = () => {
  const [filters, setFilters] = useState({
    page: 1,
    pageSize: 10,
    sort: { created_at: -1 },
  });

  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, [filters]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await api.post(`/admin/user/search`, {
        skip: (filters.page - 1) * filters.pageSize,
        limit: filters.pageSize,
        sort: filters.sort,
      });
      if (res.ok) {
        setUsers(res.data);
        setTotal(res.total);
        fetchStats(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchStats = async (usersData) => {
    try {
      const promises = usersData.map((user) =>
        api.post(`/stats/user`, { user_id: user._id, period: "ALL" }).then((res) => {
          if (res.ok) {
            return { ...user, stats: res.data };
          } else {
            return user;
          }
        }),
      );

      const results = await Promise.all(promises);

      setUsers((prev) => prev.map((u) => results.find((user) => user._id === u._id) || u));
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  return (
    <div className="p-8">
      <div className="flex justify-between items-end">
        <h1 className="text-2xl text-primary font-bold">{`Liste des utilisateurs (${total})`}</h1>
        <Create fetchData={fetchData} />
      </div>

      <Table
        header={HEADER}
        sort={filters.sort}
        onSort={(key) => {
          setFilters({ ...filters, sort: !filters.sort[key] ? { [key]: 1 } : filters.sort[key] === 1 ? { [key]: -1 } : { created_at: -1 } });
        }}
        total={total}
        loading={loading}
        page={filters.page}
        onPageChange={(page) => setFilters({ ...filters, page })}>
        {loading
          ? [...Array(filters.pageSize)].map((_, index) => (
              <tr key={index} className={`text-center cursor-pointer ${index % 2 === 0 ? "bg-neutral-50" : "bg-white"}`}>
                <td className="py-4 px-3">
                  <div className="h-4 bg-gray-200 rounded w-full" />
                </td>
                {HEADER.map((_, index) => (
                  <td key={index} className="py-4 px-3">
                    <div className="h-6 bg-gray-200 rounded w-full" />
                  </td>
                ))}
              </tr>
            ))
          : users.map((item, index) => (
              <tr
                key={index}
                className={`text-center cursor-pointer ${index % 2 === 0 ? "bg-neutral-50" : "bg-white"} hover:bg-secondary`}
                onClick={() => navigate(`/user/${item._id}`)}>
                <td className="py-4 px-3 text-left">{item.email}</td>
                <td className="py-4 px-3 text-left">{item.stats?.userLoginCount}</td>
                <td className="py-4 px-3 text-left">{item.stats?.onboardingDate?.slice(0, 10)}</td>
                <td className="py-4 px-3 text-left">{item.stats?.failedLivenessCount}</td>
                <td className="py-4 px-3 text-left">{item.stats?.failedBiometryCount}</td>
                <td className="py-4 px-3 text-left">{item.stats?.failedShareIdCount}</td>
              </tr>
            ))}
      </Table>
    </div>
  );
};

const Create = ({ fetchData }) => {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const onCreate = async () => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      const { ok, code } = await api.post("/admin/user/signup", { email, consent: true });
      if (!ok) {
        if (code === "EMAIL_ALREADY_EXISTS") {
          toast.error("L'email existe déjà");
        } else throw code;
        return;
      }
      toast.success("Utilisateur créé");
      setOpen(false);
      fetchData();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <button
        onClick={() => {
          setOpen(true);
        }}
        className="primary-button text-sm">
        <MdAddCircleOutline className="mr-2 w-4 h-4" />
        Inviter un utilisateur
      </button>

      <Modal isOpen={open} className="!w-[90vw] md:!w-[700px] p-6" onClose={() => setOpen(false)}>
        <h1 className="font-3xl font-semibold mb-5 mt-3">Inviter un utilisateur</h1>
        <div className="flex flex-col gap-4 mb-6">
          <div className="text-sm font-medium mb-2">Email</div>
          <input className="input block" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>

        <div className="flex justify-end items-center">
          <LoadingButton className="primary-button text-sm disabled:opacity-60 disabled:cursor-not-allowed" loading={isLoading} disabled={!email} onClick={() => onCreate()}>
            Create
          </LoadingButton>
        </div>
      </Modal>
    </div>
  );
};

export default List;
